<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="optin-settings-modal"
      :header="header"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'optin-settings-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
        <div
          class="u-display-flex u-margin-bottom-3"
        >
          <ar-text
            size="xs"
            text="Subscribe associated contacts to list"
            weight="bold"
            :style="{
              color: $arStyle.color.blueGrey800,
            }"
          />
          <ar-icon
            v-tooltip.top="{
              content: content,
            }"
            name="tooltip-question-mark"
            class="u-margin-left-2"
            height="15px"
            width="15px"
            :color="$arStyle.color.purple500"
          />
        </div>
        <ar-field
          :error-messages="[
            veeErrors.first('messageList')
          ]"
          :style="{
            marginTop: '15px',
          }"
        >
          <am2-message-list-select
            message-list-type="manual"
            :show-stats="false"
            :channels="['email']"
            disabled
            :value="messageListOid"
          />
          
          <ar-text
            class="u-margin-top-3"
            size="xxs"
            text="Changing message list is currenly not allowed"
            :style="{
              color: $arStyle.color.blueGrey700,
            }"
          />
        </ar-field>
        <ar-text
          v-if="tagFansIsEnabled"
          size="xs"
          text="More Settings"
          weight="bold"
          :style="{
            color: $arStyle.color.blueGrey800,
          }"
          class="u-margin-top-4"
        />

        <am2-switch-field-card
          v-if="providerName === 'DICE'"
          class="u-margin-top-3"
          title="Automatically apply tags to fans imported from DICE"
          subtitle="Tags will be customised per artist, genre & venue"
          :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'normal'"
          :value="tagFansIsEnabled"
          :display-field="tagFansIsEnabled"
          disabled
        >
          <div
            class="u-margin-top-5"
          >
            <ar-field
            >
              <ar-text
                size="xs"
                text="Enable customised tag names on"
                weight="bold"
                :style="{
                  color: $arStyle.color.blueGrey700,
                }"
              />
              <div
                class="u-display-flex u-justify-content-flex-start u-margin-top-3"
              >
                <ar-checkbox
                  label="Artists"
                  key="artists"
                  class="u-margin-right-5"
                  :v-model="!!tagFans ? tagFans.artists : null"
                  disabled
                  data-test-id="dice-automatic-tag-artists"
                  :ticked-value="!!tagFans ? tagFans.artists : null"
                />
                <ar-checkbox
                  label="Genres"
                  key="genres"
                  class="u-margin-right-5"
                  :v-model="!!tagFans ? tagFans.genres : null"
                  disabled
                  data-test-id="dice-automatic-tag-genres"
                  :ticked-value="!!tagFans ? tagFans.genres : null"
                />
                <ar-checkbox
                  label="Venues"
                  key="venues"
                  disabled
                  :v-model="!!tagFans ? tagFans.venues : null"
                  data-test-id="dice-automatic-tag-venues"
                  :ticked-value="!!tagFans ? tagFans.venues : null"
                />
              </div>
            </ar-field>
            <div
              class="u-display-flex u-margin-bottom-3 u-margin-top-5"
            >
              <ar-text
                size="xs"
                text="Tag prefix (optional)"
                weight="bold"
                :style="{
                  color: $arStyle.color.blueGrey700,
                }"
              />
              <ar-icon
                v-tooltip.top="{
                  content: diceTagNameTooltip,
                }"
                name="tooltip-question-mark"
                class="u-margin-left-2"
                height="15px"
                width="15px"
                :color="$arStyle.color.purple500"
              />
            </div>
            <ar-input
              placeholder="No tag prefix entered"
              :value="tagFans ? tagFans.prefix : null"
              autocomplete="organization"
              data-vv-name="tagName"
              data-vv-as="tag name"
              type="text"
              disabled
              data-test-id="dice-automatic-tag-prefix"
            />
            <ar-text
              size="xxs"
              :text="`For example, if you set ‘DICE’ as the prefix, the tags imported into audience republic for the genre ‘Rock’ will appear as dice-rock against the fan record.`"
              :style="{
                color: $arStyle.color.blueGrey700,
              }"
              class="u-margin-top-3"
              multiple-lines
            />
          </div>
        </am2-switch-field-card>
          
        <ar-text
          v-if="providerName === 'DICE'"
          class="u-margin-top-3"
          size="xxs"
          text="Changing apply tag settings is currenly not allowed"
          :style="{
            color: $arStyle.color.blueGrey700,
          }"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>

export default {
  name: 'OptinSettingsModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    providerName: {
      type: String,
      default: null,
    },
    messageListOid: {
      type: Number,
      default: null,
    },
    tagFans: {
      type: Object || null,
      default: null,
    }
  },

  data() {
    return {
      diceTagNameTooltip: `Tag names are automatically assigned to attendees synced via DICE depending on the artist, genre, or venue when enabled`,
    };
  },

  computed: {
    header() {
      if (!this.providerName) {
        return "Settings"
      }

      return `${this.providerName} Settings`
    },

    content() {
      if (this.providerName === "DICE") {
        return "Automatically subscribe all attendees of events from this DICE account."
      } else {
        return `Automatically subscribe all opted-in attendees of events linked to this ${this.providerName} bucket into a messaging list. Opted-in attendees will be capable of receiving emails.`
      }
    },

    tagFansIsEnabled() {
      if (!this.tagFans) {
        return false;
      }

      return !!this.tagFans.artists || !!this.tagFans.genres || !!this.tagFans.venues
    }
  },

  methods: {
    handleCloseClick() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.optin-settings-modal {
  .optin-settings-modal-body {
    padding: 20px 30px 30px;
  }
}
</style>
